import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { useSpring, animated } from 'react-spring';

export default function HomeSection() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const usps = ["Your crypto gateway to the world of DeFi, discounts from your favorite brands, expense tracking and more!"];
    const bgImages = "https://ik.imagekit.io/ecjzuksxj/poketo/Flutter%2002.png?updatedAt=1703980599768";

    const [currentUspIndex, setCurrentUspIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentUspIndex((prevIndex) => (prevIndex + 1) % usps.length);
        }, 5600);

        return () => clearInterval(interval);
    }, []);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Define animated styles for the phone using react-spring
    const phoneProps = useSpring({
        transform: `translateY(${scrollPosition * 0.8}px)`,
    });

    return (
        <div className="bg-black flex flex-col col w-screen h-screen relative overflow-hidden">

            <div className="relative isolate ">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu  blur-3xl "
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>

                <div className="flex flex-row items-center justify-center px-4 md:px-32 mt-52">
                    <div className="text-white justify-center">
                        <div className="max-w-[620px]">
                            <h1 className="text-8xl md:text-8xl lg:text-7xl font-black text-center font-poppins mb-8">
                                One App All Things Money
                            </h1>
                            <p
                                className="text-xl md:text-lg lg:text-xl text-center font-poppins mb-12"
                                style={{
                                    opacity: 0.5,
                                    transition: 'opacity 455s ease-in-in', // Adjust the duration and easing as needed
                                }}
                            >
                                {usps[currentUspIndex]}
                            </p>
                        </div>
                        <div className="flex items-center justify-center mt-32">
                            <button className="bg-white text-primary-blue py-2 px-4 rounded-full">
                                <Link
                                    activeClass="active"
                                    to="features"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Get the App
                                </Link>
                            </button>
                        </div>

                    </div>

                </div>
            </div>
            {/* </div> */}
            {/* Animated phone image */}
            <div className="flex items-center justify-center">
                <animated.img
                    id='phone'
                    className="w-[28rem] sm:w-[33rem] -mt-24 rounded-3xl "
                    src={bgImages}
                    style={phoneProps}
                    onMouseEnter={() => {
                        const el = document.getElementById(`phone`);
                        console.log('Element:', el);
                        if (el) {
                            // el.style.transform = 'scale(1.05)';
                            // el.style.transition = 'all 0.5s ease-in-out';
                            // el.style.boxShadow = '0px 0px 20px 0px #ff80b5';
                        }
                    }}
                    onMouseLeave={() => {
                        const el = document.getElementById(`phone`);
                        console.log('Element:', el);
                        if (el) {
                            // el.style.transform = 'scale(1)';
                            // el.style.boxShadow = 'none';
                            // el.transition = '';
                        }
                    }}
                />
            </div>
        </div>
    );
}
