import logo from './logo.svg';
import './App.css';
import Header from './LandingPage/Header';
import HomeSection from './LandingPage/HomeSection';
import Features from './LandingPage/Features';
import Footer from './LandingPage/Footer';
import ParallaxContainer from './LandingPage/Parallax';
import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
  return (
    <div className="absolute w-full h-full ">
      <ParallaxProvider>
      <Header />            
      <HomeSection />
      <Features />
      <Footer />
      </ParallaxProvider>
   

    </div>
  );
}

export default App;
