import { CreditCardIcon, GlobeAltIcon, CurrencyDollarIcon, SparklesIcon } from '@heroicons/react/24/outline'

const cards = [
    // {
    //     imageUrl: 'https://images.ctfassets.net/lr0atmu04u9z/3mGRoISQhNjN2PyrlJdXxX/c48b13d58df58911f2f1abd347cf745f/shutterstock_2139614619.jpg?w=1200&h=630',
    //     text: 'Crypto Gateway',
    //     description: 'Built on core blockchain, the future is here!',
    //     icon: <CurrencyDollarIcon className="w-6 h-6 text-slate-200" />,
    // },
    {
        imageUrl: 'https://ik.imagekit.io/ecjzuksxj/poketo/flutter_03.png?updatedAt=1703980349809',
        text: 'Discounts and Vouchers',
        description: 'Enjoy discounts from your favorite brands and stores.',
        icon: <CurrencyDollarIcon className="w-6 h-6 text-slate-200" />,
    },
    {
        imageUrl: 'https://ik.imagekit.io/ecjzuksxj/poketo/Flutter%2002.png?updatedAt=1703980599768',
        text: 'Cross Border Payments',
        description: 'Unbeatable Transactions costs, do more with your money!',
        icon: <GlobeAltIcon className="w-6 h-6 text-slate-200" />,
    },
    {
        imageUrl: 'https://ik.imagekit.io/ecjzuksxj/poketo/Group%2036313.png?updatedAt=1703982252362',
        text: 'Cheap and Convenient',
        description: 'Our intutive user experience makes it easy for users to interact and make the most of their crypto.',
        icon: <SparklesIcon className="w-6 h-6 text-slate-200" />,
    },
    {
        imageUrl: 'https://bitpanda-academy.imgix.net/ae6cb71f-c4db-45e3-833c-6dc74b003988/bitpanda-academy-expert-13-what-is-staking-in-crypto-header.png?auto=compress%2Cformat&fit=min&fm=jpg&q=80&w=2100',
        text: 'Staking',
        description: 'Earn 16% APY on your crypto by staking.',
        icon: <CurrencyDollarIcon className="w-6 h-6 text-slate-200" />,
    },
    {
        imageUrl: 'https://ik.imagekit.io/ecjzuksxj/poketo/flutter_05.png?updatedAt=1703980349841',
        text: 'Flexible Budgeting',
        description: 'Poketo budgeting makes it easy to track your spending, goals for guilt-free spending.',
        icon: <CurrencyDollarIcon className="w-6 h-6 text-slate-200" />,
    },
    {
        imageUrl: 'https://ik.imagekit.io/ecjzuksxj/poketo/flutter_02.png?updatedAt=1703980351640',
        text: 'Cards and more',
        description: 'Your digital wallet for your cards, tickets, passes and more all on the go',
        icon: <CreditCardIcon className="w-6 h-6 text-slate-200" />,
    },
];

export default function Features() {
    return (
        <div className="bg-black ">
            <span class="px-4 md:ml-32 mt-32 inline-flex items-center rounded-full bg-white py-1 text-black ring-1 ring-inset ring-indigo-700/10">Features</span>
            <p class="text-5xl font-extrabold mt-8 mb-8 max-w-6xl px-4 md:px-32 text-white">
                What we are doing best
            </p>
            <p class="text-lg mb-16 px-4 md:px-32 text-slate-400/60">
                We are making Blockchain and Crypto accessible to everyone. We are building a platform that will allow you to do everything you need to do with your money in one place.
            </p>
            <div className="relative isolate">
                <div
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu blur-3xl "
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>

                <div className="grid auto-rows-[432px] sm:auto-rows-[400px] md:auto-rows-[500px] lg:auto-rows-[600px] xl:auto-rows-[370px] grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-4 px-4 md:px-32">
                    {cards.map((card, i) => (
                        <div id={`card-${i + 1}`}
                            key={i}
                            className={`row-span-1 rounded-3xl border-2 border-slate-400/20 bg-transparent p-4 ${i === 2 || i === 4 ? "md:col-span-2" : "col-span-1"
                                }`}
                            style={{
                                opacity: 0.7,
                                overflow: 'hidden',
                                // filter: 'blur(0.016em)',
                            }}
                            onMouseEnter={() => {
                                const el = document.getElementById(`card-${i + 1}`);
                                console.log('Element:', el);
                                if (el) {
                                    el.style.opacity = 1;
                                    el.style.filter = 'blur(0rem)';
                                    el.style.transform = 'scale(1.02)';
                                    el.style.transition = 'all 0.5s ease-in-out';
                                }
                            }}
                            onMouseLeave={() => {
                                const el = document.getElementById(`card-${i + 1}`);
                                console.log('Element:', el);
                                if (el) {
                                    el.style.opacity = 0.7;
                                    // el.style.filter = 'blur(0.09rem)';
                                    el.style.transform = 'scale(1)';
                                }
                            }}

                        >

                            <div className="flex flex-row items-center ">
                                {card.icon}
                                <p className="text-xl ml-2 font-semibold text-slate-200">{card.text}</p>
                            </div>


                            <p className="text-lg mt-4 text-slate-400/60">{card.description}</p>
                            <div className="relative mt-10">
                                <img
                                    src={card.imageUrl}
                                    alt={`Card ${i + 1}`}
                                    className="w-full h-full object-cover mb-4 rounded-xl"
                                />
                            </div>

                        </div>
                    ))}
                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu  blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
            </div>
        </div>

    );
}



